import {
	Observable,
	Subject,
	catchError,
	of,
	switchMap,
	throwError,
} from 'rxjs';

import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Router } from '@angular/router';
import { AddFile } from '@app/core/models/add-file.model';
import { MenuOption } from '@app/core/models/menu-option.model';
import { Payload } from '@app/core/models/payload.model';
import { Record } from '@app/core/models/records.model';
import { ResponseModel } from '@app/core/models/response.model';
import { UserToken } from '@app/core/models/user-token.model';
import { ValidateTwoFactor } from '@app/core/models/validate-two-factor.model';
import { AuthUtils } from '@app/core/utils/auth.utils';
import { ROLES } from '@app/core/utils/roles.enums';
import { environment } from '@env/environment';
import { RecordService } from '../record/record.service';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular/standalone';
import { ValidateResetPasswordResponse } from '@app/core/models/validate-reset-password-response';
import { addIcons } from 'ionicons';

@Injectable()
export class AuthenticationService {
	private URL: string = environment.apiUrl + 'auth/';
	public redirectURL: string = '/';
	private Roles: any = {
		1: ROLES.ADMIN,
		2: ROLES.CARRIER,
		3: ROLES.COMPANY,
	};
	private reloadMenuSubject = new Subject<void>();
	reloadMenu$ = this.reloadMenuSubject.asObservable();

	private reloadUserNameSubject = new Subject<void>();
	reloadUserName$ = this.reloadUserNameSubject.asObservable();

	/**
	 * Constructor
	 *
	 * @param _httpClient - Injected instance of HttpClient, used for making HTTP requests to backend services.
	 * @param _userService - Injected instance of RecordService, responsible for handling user-related operations and data management.
	 * @param _jwtService - Injected instance of JwtHelperService, provides utilities for handling and decoding JSON Web Tokens (JWT).
	 * @param _router - Injected instance of Router, used for navigation and routing within the application.
	 */
	constructor(
		@Inject(HttpClient) private _httpClient: HttpClient,
		private _userService: RecordService,
		private _jwtService: JwtHelperService,
		private _router: Router,
		private platform: Platform,
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	get authenticated(): boolean {
		if (!this.accessToken) return false;
		return !AuthUtils.isTokenExpired(this.accessToken);
	}

	get userRole(): ROLES {
		const user = this.currentUser;
		return this.Roles[user.roleId];
	}

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.removeItem('access_token');
		localStorage.setItem('access_token', token);
	}

	get accessToken(): string {
		return localStorage.getItem('access_token') ?? '';
	}

	get currentUser(): UserToken {
		const token = this.accessToken;
		const decodedToken = { ...this._jwtService.decodeToken(token) };

		return decodedToken as UserToken;
	}

	get userFullName(): string {
		const token = this.accessToken;
		const decodedToken = this._jwtService.decodeToken(token);
		//
		return `${decodedToken.name} ${decodedToken.lastname} ${decodedToken.secondLastname}`;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Observable<any> {
		return this._httpClient
			.post(this.URL + 'forgot-password', {
				email: email,
			})
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	/**
	 * Change password
	 *
	 * @param request
	 */
	changePassword(request: {
		token: string;
		password: string;
		confirmPassword: string;
	}): Observable<any> {
		return this._httpClient.post(this.URL + 'change-password', request).pipe(
			switchMap((response) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string): Observable<any> {
		return this._httpClient.post('api/auth/reset-password', password);
	}

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	signIn(credentials: {
		username: string;
		password: string;
	}): Observable<Payload> {
		// Throw error, if the user is already logged in
		if (this.authenticated) {
			this._router.navigateByUrl('/home');
			return throwError(() => 'User is already logged in.');
		}

		return this._httpClient.post(this.URL + 'login', credentials).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Payload>;
				// Store the access token in the local storage if two factor authentication is not active
				if (!res.data.twoFactor) {
					this.accessToken = res.data.token;
				}

				// Set the authenticated flag to true
				// Store the routes on routes permissions.
				// this.routesPermissions = res.routes;

				// Return a new observable with the res
				return of(res.data);
			}),
		);
	}

	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Observable<boolean> {
		// Renew token

		return this._httpClient
			.post(this.URL + 'refresh-access-token', {
				accessToken: this.accessToken,
			})
			.pipe(
				switchMap((response: any) => {
					// Store the access token in the local storage
					this.accessToken = response.data.token;

					// Set the authenticated flag to true
					// Store the user on the user service
					// this._userService.user = response.user;
					// Return true
					return of(true);
				}),
				catchError((error) => {
					return of(false);
				}),
			);
	}

	/**
	 * Sign out
	 */
	signOut(fcmToken: string | null): Observable<any> {
		return this._httpClient
			.post(this.URL + 'logout', {
				FcmToken: fcmToken,
			})
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<boolean>;
					return of(response.data);
				}),
			);
	}

	/**
	 * Sign up
	 *
	 * @param user
	 */
	signUp(user: {
		email: string;
		username: string;
		password: string;
		confirmpassword: string;
		type: string;
	}): Observable<any> {
		return this._httpClient.post(this.URL + 'register-user', user).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<string>;
				return of(response.data);
			}),
		);
	}

	addMemberInfo(info: any) {
		return this._httpClient.post(this.URL + 'register/member-info', info).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<string>;
				return of(response.data);
			}),
		);
	}

	addPersonalInfo(info: any): Observable<boolean> {
		return this._httpClient
			.post(this.URL + 'register/personal-info', info)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<boolean>;
					return of(response.data);
				}),
			);
	}

	validateRegisterFlow(request: any) {
		return this._httpClient.post(this.URL + 'validate-flow', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	unlockSession(credentials: {
		email: string;
		password: string;
	}): Observable<any> {
		return this._httpClient.post('api/auth/unlock-session', credentials);
	}

	/**
	 * Check the authentication status
	 */
	check(redirectURL: string = ''): Observable<boolean> {
		// Check the access token availability
		if (!this.accessToken) {
			return of(false);
		}

		// Check the access token expire date
		if (AuthUtils.isTokenExpired(this.accessToken)) {
			return this.signInUsingToken().pipe(
				switchMap((res) => {
					return of(res);
				}),
			);
		}

		// Check if the user is logged in
		// if (!this.authenticated) {
		return of(this.authenticated);
		// }

		// If the access token exists and it didn't expire, sign in using it
		// return of(true);
		// return this.signInUsingToken().pipe(
		//   switchMap((res) => {
		//     return of(res);
		//   })
		// );
	}

	getMenu(): Observable<MenuOption[]> {
		// Renew token
		return this._httpClient.get(this.URL + 'menu-options').pipe(
			switchMap((response: any) => {
				return of(response.data);
			}),
		);
	}

	validateTwoFactorCode(validate: ValidateTwoFactor) {
		return this._httpClient
			.post(this.URL + 'validate-two-factor-code', validate)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<any>;
					this.accessToken = res.data.token;
					return of(response.data);
				}),
			);
	}

	registerCarrier(
		request: any,
		companyDocs: AddFile[],
		truckDocs: AddFile[],
	): Observable<HttpEvent<Object>> {
		const formData = new FormData();
		this.appendFormData(formData, '', request);
		// Agregar documentos de la empresa al FormData
		companyDocs.forEach((doc, index) => {
			formData.append(`companyDocs[${index}].name`, doc.name);
			formData.append(
				`companyDocs[${index}].expirationDate`,
				doc.expirationDate.toISOString(),
			);
			formData.append(`companyDocs[${index}].file`, doc.file, doc.file.name);
		});

		// Agregar documentos de camiones al FormData
		truckDocs.forEach((doc, index) => {
			formData.append(`truckDocs[${index}].name`, doc.name);
			formData.append(
				`truckDocs[${index}].expirationDate`,
				doc.expirationDate.toISOString(),
			);
			formData.append(`truckDocs[${index}].file`, doc.file, doc.file.name);
		});

		return this._httpClient
			.post(this.URL + 'register-carrier', formData, {
				reportProgress: true,
				observe: 'events',
			})
			.pipe(
				switchMap((response: HttpEvent<Object>) => {
					return of(response);
				}),
			);
	}

	registerContactInformation(request: any) {
		this._httpClient.post(`${this.URL}register-information`, {});
	}

	/**
	 *  Register company
	 * @param request Request data to register
	 * @returns
	 */
	registerCompany(
		request: any,
		companyDocs: AddFile[],
		truckDocs: AddFile[],
	): Observable<HttpEvent<Object>> {
		const formData = new FormData();
		this.appendFormData(formData, '', request);
		// Agregar documentos de la empresa al FormData
		companyDocs.forEach((doc, index) => {
			formData.append(`companyDocs[${index}].name`, doc.name);
			formData.append(
				`companyDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`companyDocs[${index}].file`, doc.file, doc.file.name);
		});

		// Agregar documentos de camiones al FormData
		truckDocs.forEach((doc, index) => {
			formData.append(`truckDocs[${index}].name`, doc.name);
			formData.append(
				`truckDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`truckDocs[${index}].file`, doc.file, doc.file.name);
		});
		return this._httpClient
			.post(this.URL + 'register-carrier', formData, {
				reportProgress: true,
				observe: 'events',
			})
			.pipe(
				switchMap((response: HttpEvent<Object>) => {
					return of(response);
				}),
			);
	}

	/**
	 *  Register company
	 * @param request Request data to register
	 * @returns
	 */
	finishRegister(
		request: any,
		companyDocs: AddFile[],
		truckDocs: AddFile[],
		referrals: any[],
	): Observable<HttpEvent<Object>> {
		const formData = new FormData();
		this.appendFormData(formData, '', request);
		// Agregar documentos de la empresa al FormData
		companyDocs.forEach((doc, index) => {
			formData.append(`companyDocs[${index}].name`, doc.name);
			formData.append(
				`companyDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`companyDocs[${index}].file`, doc.file, doc.file.name);
		});

		// Agregar documentos de camiones al FormData
		truckDocs.forEach((doc, index) => {
			formData.append(`truckDocs[${index}].name`, doc.name);
			formData.append(
				`truckDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`truckDocs[${index}].file`, doc.file, doc.file.name);
		});

		referrals.forEach((ref, index) => {
			formData.append(`referrals[${index}].type`, ref.type);
			formData.append(`referrals[${index}].companyName`, ref.companyName);
			formData.append(`referrals[${index}].contact`, ref.contact);
			formData.append(`referrals[${index}].phoneNumber`, ref.phoneNumber);
		});

		return this._httpClient
			.post(this.URL + 'finish-register', formData, {
				reportProgress: true,
				observe: 'events',
			})
			.pipe(
				switchMap((response: HttpEvent<Object>) => {
					return of(response);
				}),
			);
	}

	getRegisterFlowByGuid(Guid: string): Observable<Record> {
		return this._httpClient
			.get(this.URL + 'get-register-flow?Guid=' + Guid)
			.pipe(
				switchMap((response: any) => {
					return of(response.data);
				}),
			);
	}

	verifyUsername(username: string): Observable<boolean> {
		return this._httpClient
			.get(this.URL + 'verify-username?username=' + username)
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	verifyEmail(email: string): Observable<boolean> {
		return this._httpClient.get(this.URL + 'verify-email?email=' + email).pipe(
			switchMap((response) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	verifyPhoneNumber(phoneNumber: string): Observable<boolean> {
		return this._httpClient
			.get(this.URL + 'verify-phonenumber?PhoneNumber=' + phoneNumber)
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	storageToken(token: string): Observable<boolean> {
		return this._httpClient
			.post(this.URL + 'storage-token', {
				FcmToken: token,
			})
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	validateTokenResetPassword(
		token: string,
	): Observable<ValidateResetPasswordResponse> {
		return this._httpClient
			.post(this.URL + 'validate-reset-password', {
				token: token,
			})
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<ValidateResetPasswordResponse>;
					return of(res.data);
				}),
			);
	}

	reloadMenu() {
		this.reloadMenuSubject.next();
	}

	reloadUserName() {
		this.reloadUserNameSubject.next();
	}

	deleteAccount(password: string) {
		return this._httpClient
			.post(this.URL + 'delete-account', {
				password: password,
			})
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	async getFcmToken(): Promise<string | null> {
		if (this.platform.is('hybrid')) {
			const { value } = await Preferences.get({ key: 'FcmToken' });
			return value;
		} else return null;
	}

	async saveFcmToken(token: string) {
		if (this.platform.is('hybrid')) {
			await Preferences.set({
				key: 'FcmToken',
				value: token,
			});
		}
	}

	async removeFcmToken() {
		if (this.platform.is('hybrid')) {
			await Preferences.remove({
				key: 'FcmToken',
			});
		}
	}

	/**
	 * Append props to Form Data.
	 * @param formData FormData
	 * @param prefix Prefix
	 * @param data Request
	 */
	private appendFormData(formData: FormData, prefix: string, data: any) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key];
				const newKey = prefix ? `${prefix}.${key}` : key;
				if (typeof value === 'object') {
					this.appendFormData(formData, newKey, value);
				} else {
					if (value) {
						formData.append(newKey, value);
					}
				}
			}
		}
	}
}
